<template>
  <div v-if="dayInformation" class="hoursCalendarSelect">
    <div class="">
      <div class="hoursCalendarSelect__day">
        <data-formatted date-format="EEE" :data="day" />
      </div>
      <div class="hoursCalendarSelect__data">
        <data-formatted date-format="dd MMM" :data="day" />
      </div>
    </div>
    <div
      :id="`w-${count}`"
      class="hoursCalendarSelect__scroll"
      :class="{ 'hoursCalendarSelect__scroll-last': count === 3 }"
    >
      <div
        class="hoursCalendarSelect__wrapper"
        v-for="(time, i) in dayInformation"
      >
        <div
          v-if="time !== null && showHours(time)"
          class="hoursCalendarSelect__time"
          :class="{ 'hoursCalendarSelect__time-active': select[i] }"
          @click="selectHour(time, i)"
        >
          <data-formatted date-format="HH:mm" :data="time" />
        </div>
        <div v-else class="hoursCalendarSelect__time-null">-</div>
      </div>
    </div>
  </div>
</template>

<script>
import DataFormatted from "@/components/components-helpers/DataFormatted";
import { mapGetters } from "vuex";

export default {
  components: { DataFormatted },
  props: {
    count: 0,
    day: Date,
    dayInformation: {
      type: Object,
      default: {
        hours: "",
      },
    },
  },
  name: "HoursCalendarSelect",
  data() {
    return {
      select: [],
    };
  },
  methods: {
    selectHour(time, i) {
      if (this.reschedule) {
        this.selectHourReschedule(time, i);
        return;
      }
      this.select[i] = this.select[i] !== true;
      let value = this.$store.getters.HOUR;
      if (value.includes(time)) {
        value = value.filter((element) => element !== time);
      } else {
        value.push(time);
      }
      this.$store.commit("SET_HOUR", value);
    },
    selectHourReschedule(time, i) {
      let value = this.$store.getters.HOUR;
      if (value.includes(time)) {
        this.select[i] = false;
        value = [];
        this.$store.commit("SET_HOUR", value);
      } else if (value.length < 1) {
        this.select[i] = true;
        value = [time];
        this.$store.commit("SET_HOUR", value);
      }
    },
    showHours(time) {
      let catItem = JSON.parse(localStorage.getItem("cart"));
      if (!!!catItem) return true;
      let cartDate = [];
      catItem.forEach((item) => {
        cartDate.push(item.value);
      });
      return !cartDate.includes(time);
    },
  },
  computed: {
    ...mapGetters({
      reschedule: "RESCHEDULE",
    }),
  },
};
</script>

<style></style>
