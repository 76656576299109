<template>
  <div class="calendarCart">
    <h2 class="calendarCart__title">
      {{ appointmentInformation.options.services.name }}
      <span class="calendarCart__lang">{{
        appointmentInformation.options.language.code
      }}</span>
    </h2>
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <avatar
          user-role="experts"
          size="100"
          :image-name="appointmentInformation.options.expert.image"
          :user-code="appointmentInformation.options.expert.code"
          height="80px"
          width="80px"
        />
        <div class="ms-3">
          <div class="calendarCart__name">
            <short-name
              :name="
                appointmentInformation.options.expert.first_name +
                ' ' +
                appointmentInformation.options.expert.last_name
              "
            />
          </div>
          <div
            v-if="
              appointmentInformation.options.expert.translations &&
              appointmentInformation.options.expert.translations[0].position
            "
            class="calendarCart__position"
          >
            {{ appointmentInformation.options.expert.translations[0].position }}
          </div>
          <div
            v-if="appointmentInformation.options.expert.position"
            class="calendarCart__position"
          >
            {{ appointmentInformation.options.expert.position }}
          </div>
        </div>
      </div>
      <div>
        <div class="calendarCart__type">
          {{ appointmentInformation.options.type.name }}
        </div>
        <div class="calendarCart__duration">
          <small>
            {{ $t("duration") }}
            <duration-time
              :data="appointmentInformation.options.type.duration"
            />
          </small>
        </div>
        <div class="calendarCart__duration">
          <small>
            {{ $t("FullPrice") }}
            €{{ cost.fullPrice }}
          </small>
        </div>
        <div class="calendarCart__duration">
          {{ $t("YouPrice") }}
          €{{ cost.employeePrice }}
        </div>
      </div>
    </div>
    <div class="calendarCart__calendarHeader">
      {{ $t("employeeCalendarTime") }}
    </div>
    <div class="calendarCart__dayWrapper">
      <schedule-preloader v-if="loadedState" />
      <div
        v-if="showPrevArrow && !loadedState"
        @click="prevInterval()"
        class="calendarCart__back"
      >
        <horizontal-arrow />
      </div>
      <div v-if="!loadedState" class="d-flex justify-content-between w-100">
        <hours-calendar-select
          v-for="(day, i) in dayInformation"
          :day="dayPeriod[i]"
          :count="i"
          :day-information="day"
        />
      </div>
      <div
        v-if="showNextArrow && !loadedState"
        @click="nextInterval()"
        class="calendarCart__pointer"
      >
        <horizontal-arrow />
      </div>
    </div>
    <div
      class="d-xl-flex flex-wrap justify-content-center justify-content-md-between align-items-center"
    >
      <div class="calendarCart__footer-price mb-md-3">
        {{ $t("TotalCoast") }}
        <span class="calendarCart__footer-coast">
          €{{ totalCoast.toFixed(2) }}
        </span>
      </div>
      <base-button
        :is-loaded="loaded"
        @click="addToCard"
        class="calendarCart__button"
        margin-left="0"
      >
        {{ reschedule ? $t("Save") : $t("addToCart") }}
      </base-button>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/UI/images/Avatar";
import BaseButton from "@/components/UI/buttons/BaseButton";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import HoursCalendarSelect from "@/components/elements/calendar/HoursCalendarSelect";
import ShortName from "@/components/components-helpers/ShortName";
import DurationTime from "@/components/components-helpers/DurationTime";
import { mapGetters } from "vuex";
import { addDays, format, intervalToDuration } from "date-fns";
import { useToast } from "vue-toastification";
import SchedulePreloader from "@/components/UI/icons/preloaders/SchedulePreloader";

export default {
  props: {
    appointmentInformation: {
      type: Object,
      default: { options: { services: { name: "" } } },
    },
  },
  name: "CalendarCart",
  components: {
    SchedulePreloader,
    DurationTime,
    ShortName,
    HoursCalendarSelect,
    HorizontalArrow,
    BaseButton,
    Avatar,
  },
  setup() {
    const toast = useToast();

    return {
      toast,
    };
  },
  data() {
    return {
      loaded: false,
      cost: {},
      showNextArrow: true,
      showPrevArrow: false,
    };
  },
  async mounted() {
    this.$socket.emit("join-cart");
    this.cost = await this.getCoast();
    this.$store.commit(
      "SET_CALENDAR_DAY",
      new Date(new Date().setDate(new Date().getDate() + 1))
    );
    setTimeout(() => {
      this.syncScroll();
    }, 400);
  },
  methods: {
    async getCoast() {
      return await this.$http
        .get(
          `/cart/price/consultation/${this.appointmentInformation.options.type.id}`
        )
        .then(({ data }) => {
          return data.body;
        });
    },
    nextInterval() {
      let nextInterval = addDays(new Date(this.today), 4);
      nextInterval =
        format(new Date(nextInterval), "yyyy-MM-dd") + "T00:00:00.000Z";
      this.$store.commit("SET_CALENDAR_DAY", nextInterval);
      const interval = intervalToDuration({
        start: new Date(nextInterval),
        end: new Date(),
      });
      this.showPrevArrow = true;
      this.showNextArrow = interval.months === 0;
      setTimeout(() => {
        this.syncScroll();
      }, 400);
    },
    prevInterval() {
      let prevInterval = addDays(new Date(this.today), -4);
      let startDate = new Date(new Date().setDate(new Date().getDate() + 1));
      prevInterval =
        format(new Date(prevInterval), "yyyy-MM-dd") + "T00:00:00.000Z";
      startDate = format(new Date(startDate), "yyyy-MM-dd") + "T00:00:00.000Z";
      this.$store.commit("SET_CALENDAR_DAY", prevInterval);
      this.showNextArrow = true;
      this.showPrevArrow =
        new Date(startDate).getTime() < new Date(prevInterval).getTime();
      setTimeout(() => {
        this.syncScroll();
      }, 400);
    },
    addToCard() {
      let hours = [...this.$store.getters.HOUR];
      let options = { ...this.appointmentInformation.options };
      if (this.reschedule) {
        this.rescheduleAppointment();
        return;
      }
      let items = [];
      hours.forEach((item) => {
        items.push({
          consultation_id: +options.type.id,
          language_id: +options.language.id,
          time: item,
        });
      });
      this.$http
        .post("/cart/items", { items: items })
        .then(() => {
          this.$store.commit("SET_CART_ITEMS", {});
          this.$router.push("/cart");
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.toast.error(err.response.data.message);
          } else {
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    rescheduleAppointment() {
      this.loaded = true;
      const appointment_id = this.$store.getters.BOOK_DATA.appointment_id;
      const time = new Date(this.$store.getters.HOUR).toISOString();
      this.$http
        .patch(`appointments/${appointment_id}/move`, {
          time: time,
        })
        .then(() => {
          this.toast.success(this.$t("changesSave"));
          this.$store.commit("SET_RESCHEDULE", null);
          this.getAppointments();
          this.$router.push("/history");
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.toast.error(err.response.data.message);
          } else {
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    getAppointments() {
      this.$http.get("appointments/upcoming").then(({ data }) => {
        this.$store.commit("SET_UPCOMING_APPOINTMENT", data.body[0]);
        this.$store.commit("SET_APPOINTMENTS", data.body);
      });
    },
    syncScroll() {
      this.$nextTick(() => {
        const firstColl = document.getElementById("w-0");
        const secondColl = document.getElementById("w-1");
        const thirdColl = document.getElementById("w-2");
        const fourColl = document.getElementById("w-3");
        firstColl.addEventListener("scroll", (eventTarget) => {
          secondColl.removeEventListener("scroll", eventTarget);
          thirdColl.removeEventListener("scroll", eventTarget);
          fourColl.removeEventListener("scroll", eventTarget);
          secondColl.scrollTop = eventTarget.target.scrollTop;
          thirdColl.scrollTop = eventTarget.target.scrollTop;
          fourColl.scrollTop = eventTarget.target.scrollTop;
        });
        secondColl.addEventListener("scroll", (eventTarget) => {
          firstColl.removeEventListener("scroll", eventTarget);
          thirdColl.removeEventListener("scroll", eventTarget);
          fourColl.removeEventListener("scroll", eventTarget);
          firstColl.scrollTop = eventTarget.target.scrollTop;
          thirdColl.scrollTop = eventTarget.target.scrollTop;
          fourColl.scrollTop = eventTarget.target.scrollTop;
        });
        thirdColl.addEventListener("scroll", (eventTarget) => {
          secondColl.removeEventListener("scroll", eventTarget);
          firstColl.removeEventListener("scroll", eventTarget);
          fourColl.removeEventListener("scroll", eventTarget);
          secondColl.scrollTop = eventTarget.target.scrollTop;
          firstColl.scrollTop = eventTarget.target.scrollTop;
          fourColl.scrollTop = eventTarget.target.scrollTop;
        });
        fourColl.addEventListener("scroll", (eventTarget) => {
          secondColl.removeEventListener("scroll", eventTarget);
          thirdColl.removeEventListener("scroll", eventTarget);
          firstColl.removeEventListener("scroll", eventTarget);
          secondColl.scrollTop = eventTarget.target.scrollTop;
          thirdColl.scrollTop = eventTarget.target.scrollTop;
          firstColl.scrollTop = eventTarget.target.scrollTop;
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      today: "CALENDAR_DAY",
      loginUser: "CURRENT_USER_INFORMATION",
      dayInformation: "DAY_OPTIONS",
      dayPeriod: "DAY_INTERVAL",
      reschedule: "RESCHEDULE",
      rebook: "REBOOK",
      loadedState: "LOADED_STATE",
    }),
    totalCoast() {
      let hours = [...this.$store.getters.HOUR];
      let coast = 0;
      hours.forEach(() => {
        coast += +this.cost.employeePrice;
      });

      return coast;
    },
  },
  watch: {
    async loadedState(value) {
      if (!value) {
        this.cost = await this.getCoast();
        setTimeout(() => {
          this.syncScroll();
        }, 400);
      }
    },
  },
};
</script>

<style></style>
