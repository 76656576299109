<template>
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="w-max-content m-0">{{ $t("headerCalendarBtn") }}</h1>
    <router-link
      to="/working-hours"
      class="d-flex align-items-center cursor-pointer"
    >
      <working-hours-icon />
      <div class="expertCalendarPage__workingHours">
        {{ $t("workingHours") }}
      </div>
    </router-link>
  </div>
  <!--  <div class="expertCalendarPage__statisticSwitch">-->
  <!--    <router-link to="/working-hours" class="expertCalendarPage__statisticSwitchButton"-->
  <!--                 :class="{'expertCalendarPage__statisticSwitchButton-active':statistics.day}"-->
  <!--                 @click="switchResponse()">Day-->
  <!--    </router-link>-->
  <!--    <div class="expertCalendarPage__statisticSwitchButton"-->
  <!--         :class="{'expertCalendarPage__statisticSwitchButton-active':statistics.week}"-->
  <!--         @click="switchResponse()">{{ $t('weak') }}-->
  <!--    </div>-->
  <!--    <div class="expertCalendarPage__statisticSwitchButton"-->
  <!--         :class="{'expertCalendarPage__statisticSwitchButton-active':statistics.month}"-->
  <!--         @click="switchResponse()">{{ $t('month') }}-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="expertCalendarPage__currentMonths">
    <div class="cursor-pointer mr-15" @click="goToPreviouslyMonth()">
      <arrow-left />
    </div>
    <div>
      <span class="weight-600">
        <data-formatted date-format="MMMM" :data="monthToView" />
      </span>
      <span class="ms-2">
        <data-formatted date-format="yyyy" :data="monthToView" />
      </span>
    </div>
    <div class="cursor-pointer ml-15" @click="goToNexMonth()">
      <arrow-right />
    </div>
  </div>
  <month-calendar-response :current-month="monthToView" />
</template>

<script>
import WorkingHoursIcon from "@/components/UI/icons/WorkingHoursIcon";
import MonthCalendarResponse from "@/components/elements/calendar/MonthCalendarResponse";
import { mapGetters } from "vuex";
import DataFormatted from "@/components/components-helpers/DataFormatted";
import ArrowRight from "@/components/UI/icons/arrows/ArrowRight";
import ArrowLeft from "@/components/UI/icons/arrows/ArrowLeft";
import { addMonths } from "date-fns";

export default {
  name: "ExpertCalendarPage",
  components: {
    ArrowLeft,
    ArrowRight,
    DataFormatted,
    MonthCalendarResponse,
    WorkingHoursIcon,
  },
  data() {
    return {
      monthToView: new Date(),
      statistics: {
        day: false,
        week: false,
        month: false,
      },
    };
  },
  methods: {
    switchResponse() {},
    goToPreviouslyMonth() {
      this.monthToView = addMonths(new Date(this.monthToView), -1);
    },
    goToNexMonth() {
      this.monthToView = addMonths(new Date(this.monthToView), 1);
    },
  },
  computed: {
    ...mapGetters({
      calendarDay: "CALENDAR_DAY_SELECT",
    }),
  },
};
</script>

<style></style>
