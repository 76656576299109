<template>
  <div class="calendarSelectGroup">
    <div class="calendarSelectGroup__title">
      {{ $t("employeeCalendarTitle") }}
    </div>
    <div class="calendarSelectGroup__selectContainer">
      <div class="calendarSelectGroup__selectGroup">
        <div class="calendarSelectGroup__selectTitle">
          {{ $t("employeeCalendarLanguage") }}
        </div>
        <vue-multiselect
          v-if="selectedLoadedState.language"
          :disabled="reschedule || rebook || !!selectedOptions.language"
          @select="onInputChange('language')"
          class="calendarSelectGroup__multiselect accountOverviewMultiselect"
          :placeholder="$t('employeeCalendarSelectLanguage')"
          :show-labels="false"
          label="name"
          track-by="name"
          v-model="selectedOptions.language"
          open-direction="bottom"
          :options="languageOptions"
          :searchable="true"
        >
        </vue-multiselect>
        <div v-else class="calendarSelectGroup__selectPreload">
          <loader-icon />
        </div>
      </div>
      <div class="calendarSelectGroup__selectGroup">
        <div class="calendarSelectGroup__selectTitle">
          {{ $t("employeeCalendarService") }}
        </div>
        <vue-multiselect
          v-if="selectedLoadedState.services"
          :disabled="reschedule || rebook || !!selectedOptions.services"
          @select="onInputChange('services')"
          class="calendarSelectGroup__multiselect accountOverviewMultiselect"
          v-model="selectedOptions.services"
          :show-labels="false"
          :options="servicesOptions"
          :placeholder="$t('employeeCalendarSelectService')"
          label="name"
          track-by="name"
          open-direction="bottom"
          :searchable="true"
        >
        </vue-multiselect>
        <div v-else class="calendarSelectGroup__selectPreload">
          <loader-icon />
        </div>
      </div>
      <div class="calendarSelectGroup__selectGroup">
        <div class="calendarSelectGroup__selectTitle">
          {{ $t("employeeCalendarExpert") }}
        </div>
        <vue-multiselect
          v-if="selectedLoadedState.expert"
          :disabled="reschedule || rebook || !!selectedOptions.expert"
          @select="onInputChange('expert')"
          class="calendarSelectGroup__multiselect accountOverviewMultiselect"
          :show-labels="false"
          v-model="selectedOptions.expert"
          :options="expertsOptions"
          :placeholder="$t('employeeCalendarSelectExpert')"
          label="first_name"
          track-by="first_name"
          open-direction="bottom"
          :searchable="true"
        >
        </vue-multiselect>
        <div v-else class="calendarSelectGroup__selectPreload">
          <loader-icon />
        </div>
      </div>
      <div class="calendarSelectGroup__selectGroup">
        <div class="calendarSelectGroup__selectTitle">
          {{ $t("employeeCalendarConsultation") }}
        </div>
        <vue-multiselect
          v-if="selectedLoadedState.type"
          :disabled="
            !selectedOptions.services ||
            !selectedOptions.expert ||
            !selectedOptions.language ||
            reschedule ||
            rebook
          "
          @select="onInputChange('type')"
          class="calendarSelectGroup__multiselect accountOverviewMultiselect"
          :placeholder="$t('employeeCalendarSelectType')"
          label="name"
          track-by="name"
          open-direction="bottom"
          :show-labels="false"
          :searchable="true"
          :custom-label="customLabel"
          v-model="selectedOptions.type"
          :options="typeOptions"
        >
          <template v-slot:option="{ option }">
            {{ option.translations[0].name }}
          </template>
        </vue-multiselect>
        <div v-else class="calendarSelectGroup__selectPreload">
          <loader-icon />
        </div>
      </div>
    </div>
    <div class="calendarSelectGroup__footer">
      <base-button
        v-if="reschedule === null && rebook === null"
        @click="clearSelected"
        >{{ $t("clear") }}
      </base-button>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import BaseButton from "@/components/UI/buttons/BaseButton";
import { mapGetters } from "vuex";
import LoaderIcon from "@/components/UI/icons/preloaders/LoaderIcon";

export default {
  props: {
    selected: Function,
    languageOptions: Array,
    expertsOptions: Array,
    servicesOptions: Array,
    typeOptions: Array,
  },
  name: "CalendarSelectGroup",
  components: { LoaderIcon, BaseButton, VueMultiselect },
  data() {
    return {
      selectedOptions: {
        language: null,
        services: null,
        expert: null,
        type: null,
      },
      selectedLoadedState: {
        language: true,
        services: true,
        expert: true,
        type: true,
      },
    };
  },
  mounted() {
    this.selectedOptions = { ...this.$store.getters.BOOK_DATA };
    if (!!JSON.parse(localStorage.getItem("options"))) {
      this.selectedOptions = JSON.parse(localStorage.getItem("options"));
    }
    this.onInputChange();
  },
  methods: {
    customLabel({ translations }) {
      return `${translations[0].name}`;
    },
    onInputChange(name) {
      this.$store.commit("SET_CALENDAR_SELECT", this.selectedOptions);
      if (!!name) {
        this.$nextTick(() => {
          for (let i in this.selectedOptions) {
            this.selectedLoadedState[i] = !!this.selectedOptions[i];
          }
          setTimeout(() => {
            for (let i in this.selectedLoadedState) {
              this.selectedLoadedState[i] = true;
            }
          }, 250);
        });
      }
    },
    clearSelected() {
      this.selectedOptions.language = null;
      this.selectedOptions.services = null;
      this.selectedOptions.expert = null;
      this.selectedOptions.type = null;
      localStorage.removeItem("options");
      this.$store.commit("SET_CALENDAR_SELECT", []);
      this.$store.commit("SET_SHOW_CALENDAR", false);
      this.$store.commit("SET_HOUR", []);
      for (let i in this.selectedLoadedState) {
        this.selectedLoadedState[i] = false;
      }
      setTimeout(() => {
        for (let i in this.selectedLoadedState) {
          this.selectedLoadedState[i] = true;
        }
      }, 250);
    },
  },
  computed: {
    ...mapGetters({
      reschedule: "RESCHEDULE",
      rebook: "REBOOK",
    }),
  },
};
</script>
<style></style>
