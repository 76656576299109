<template>
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.609863 0.887939H4.06586L9.58587 6.33594L4.06586 11.7839H0.609863L6.15386 6.33594L0.609863 0.887939Z"
              fill="black"/>
    </svg>
</template>

<script>
export default {
    name: "ArrowRight"
}
</script>

<style>

</style>
