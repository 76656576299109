<template>
  <main-layout>
    <employee-calendar-page v-if="loginUser.role_id === 4" />
    <expert-calendar-page v-if="loginUser.role_id === 2" />
  </main-layout>
</template>

<script>
import { mapGetters } from "vuex";
import MainLayout from "@/layouts/MainLayout";
import EmployeeCalendarPage from "@/components/pages-templates/calendar/EmployeeCalendarPage";
import ExpertCalendarPage from "@/components/pages-templates/calendar/ExpertCalendarPage";

export default {
  name: "CalendarPage",
  components: { ExpertCalendarPage, EmployeeCalendarPage, MainLayout },
  data() {
    return {
      showReschedule: true,
    };
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
      popupsInformation: "RESCHEDULE_APPOINTMENT_POPUP",
      reschedule: "RESCHEDULE",
    }),
  },
  beforeRouteLeave(to, from, next) {
    const inputs = {
      language: null,
      services: null,
      expert: null,
      type: null,
    };
    this.$store.commit("SET_REBOOK", null);
    this.$store.commit("SET_BOOK_DATA", inputs);
    if (this.reschedule) {
      let changesPopup = {
        from: from.href,
        show: true,
        to: to.path,
        save: null,
      };
      this.$store.commit("SET_RESCHEDULE_APPOINTMENT_POPUP", changesPopup);
    } else {
      return next();
    }
  },
  watch: {
    popupsInformation: {
      deep: true,
      handler(newCount) {
        if (newCount.save) {
          this.$store.commit("SET_RESCHEDULE", true);
          this.showReschedule = true;
        }
        if (newCount.leave) {
          this.$store.commit("SET_RESCHEDULE", null);
          this.$router.push(newCount.to);
        }
      },
    },
  },
};
</script>

<style></style>
