<template>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12 21.8909C16.909 21.8909 20.889 17.9112 20.889 13.0019C20.889 8.09252 16.9093 4.11281 12 4.11281C7.09067 4.11281 3.11095 8.09252 3.11095 13.0019C3.11095 17.9112 7.09067 21.8909 12 21.8909ZM12 23.002C17.5229 23.002 22 18.5248 22 13.002C22 7.4791 17.5229 3.00195 12 3.00195C6.47714 3.00195 2 7.4791 2 13.002C2 18.5248 6.47714 23.002 12 23.002Z"
              fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12.0001 6.33518C12.3068 6.33518 12.5557 6.58388 12.5557 6.8908V12.446H18.1109C18.4178 12.446 18.6665 12.6949 18.6665 13.0017C18.6665 13.3084 18.4178 13.5573 18.1109 13.5573H12C11.6932 13.5573 11.4443 13.3084 11.4443 13.0017V6.8907C11.4443 6.58378 11.6932 6.33508 12 6.33508L12.0001 6.33518Z"
              fill="black"/>
    </svg>

</template>

<script>
export default {
    name: "WorkingHoursIcon"
}
</script>

<style>

</style>


