<template>
  <div class="calendarPage__monthContainer">
    <div class="d-flex">
      <div class="calendarPage__weeklyCellHeader">{{ $t("mon") }}</div>
      <div class="calendarPage__weeklyCellHeader">{{ $t("tue") }}</div>
      <div class="calendarPage__weeklyCellHeader">{{ $t("wed") }}</div>
      <div class="calendarPage__weeklyCellHeader">{{ $t("thu") }}</div>
      <div class="calendarPage__weeklyCellHeader">{{ $t("fri") }}</div>
      <div class="calendarPage__weeklyCellHeader">{{ $t("sat") }}</div>
      <div class="calendarPage__weeklyCellHeader">{{ $t("sun") }}</div>
    </div>
    <div v-for="week in month" class="d-flex">
      <month-calendar-cell
        v-for="day in week"
        :data-values="day.value"
        :view-month="currentMonth"
        :data="day.day"
      />
    </div>
  </div>
</template>

<script>
import {
  add,
  eachDayOfInterval,
  eachWeekOfInterval,
  isSameDay,
} from "date-fns";
import DataDay from "@/components/components-helpers/DataDay";
import MonthCalendarCell from "@/components/elements/calendar/MonthCalendarCell";

export default {
  props: {
    currentMonth: Date,
  },
  name: "MonthCalendarResponse",
  components: { MonthCalendarCell, DataDay },
  data() {
    return {
      month: {},
      daysValue: {},
    };
  },
  mounted() {
    this.createMonthCalendar();
  },
  methods: {
    async createMonthCalendar() {
      let date = this.currentMonth,
        y = date.getFullYear(),
        m = date.getMonth();
      let start = new Date(y, m, 1);
      let end = new Date(y, m + 1, 0);
      const dayValues = await this.getDaysValue(start, end);
      const month = await this.setDataInterval(start, end);
      if (dayValues && dayValues.length > 0) {
        dayValues.forEach((value) => {
          month.forEach((week) => {
            week.forEach((day) => {
              if (isSameDay(new Date(value.time), new Date(day.day))) {
                day.value.push(value);
              }
            });
          });
        });
      }
      this.month = month;
    },
    async getDaysValue(start, end) {
      return await this.$http
        .get(
          `appointments/start/${start.toISOString()}/end/${end.toISOString()}`,
          {
            params: {
              canceled: false,
            },
          }
        )
        .then(({ data }) => {
          return data.body;
        });
    },
    async setDataInterval(start, end) {
      let days = [];
      let month = [];
      let weeks = eachWeekOfInterval(
        {
          start: start,
          end: end,
        },
        { weekStartsOn: 1 }
      );
      weeks.forEach((week) => {
        days.push(
          eachDayOfInterval({
            start: new Date(week),
            end: add(new Date(week), { days: 6 }),
          })
        );
      });
      days = JSON.parse(JSON.stringify(days));
      days.forEach((week) => {
        let days = [];
        week.forEach((day) => {
          days.push({
            day: day,
            value: [],
          });
        });
        month.push(days);
      });
      return month;
    },
  },
  watch: {
    currentMonth: {
      deep: true,
      handler() {
        this.createMonthCalendar();
      },
    },
  },
};
</script>

<style></style>
