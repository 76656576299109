<template>
  <div class="calendarPage__titleContainer">
    <base-button @click="$router.back()" :back="true">
      <horizontal-arrow />
    </base-button>
    <h3 class="calendarPage__title">{{ $t("headerCalendarBtn") }}</h3>
  </div>
  <div class="d-flex justify-content-between align-items-stretch">
    <calendar-select-group
      :selected="selected"
      :services-options="servicesList"
      :experts-options="expertsList"
      :language-options="languagesList"
      :type-options="consultationList"
    />
    <calendar-cart
      v-if="showCalendar"
      :appointmentInformation="calendarInformation"
    />
  </div>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import BaseButton from "@/components/UI/buttons/BaseButton";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import CalendarSelectGroup from "@/components/elements/calendar/CalendarSelectGroup";
import CalendarCart from "@/components/elements/card/CalendarCart";
import { add, format } from "date-fns";
import { mapGetters } from "vuex";

export default {
  name: "EmployeeCalendarPage",
  components: {
    CalendarCart,
    CalendarSelectGroup,
    HorizontalArrow,
    BaseButton,
    MainLayout,
  },
  data() {
    return {
      languagesList: [],
      expertsList: [],
      servicesList: [],
      consultationList: [],
      calendarInformation: [],
      selectedOptions: {},
    };
  },
  mounted() {
    this.$store.commit("SET_HOUR", []);
    this.selected();
  },
  methods: {
    getLists() {
      this.$nextTick(() => {
        let language_id = this.selectedOptions.language
          ? this.selectedOptions.language.id
          : null;
        let service_id = this.selectedOptions.services
          ? this.selectedOptions.services.id
          : null;
        let expert_id = this.selectedOptions.expert
          ? this.selectedOptions.expert.user_id
          : null;
        let expert_code = this.selectedOptions.expert
          ? this.selectedOptions.expert.code
          : null;
        this.$http
          .get("/languages", {
            params: {
              expert: expert_code,
            },
          })
          .then(({ data }) => {
            this.languagesList = data.body;
          });
        this.$http
          .get("/experts", {
            params: {
              notActivated: this.loginUser.role_id !== 4,
              blocked: this.loginUser.role_id !== 4,
              service: service_id,
              language: language_id,
            },
          })
          .then(({ data }) => {
            this.expertsList = data.body;
          });
        this.$http
          .get("/services", {
            params: {
              experts: expert_code,
            },
          })
          .then(({ data }) => {
            this.servicesList = data.body;
          });
        if (expert_id !== null && service_id !== null) {
          this.$http
            .get(`/consultations/expert/${expert_id}/service/${service_id}`)
            .then(({ data }) => {
              this.consultationList = data.body;
            });
        }
      });
    },
    selected() {
      this.getLists();
      if (
        !!this.selectedOptions.language &&
        !!this.selectedOptions.services &&
        !!this.selectedOptions.expert &&
        !!this.selectedOptions.type
      ) {
        this.getSchedule();
      }
    },
    getSchedule() {
      this.$store.commit("SET_LOADED_STATE", true);
      let today = format(new Date(this.today), "yyyy-MM-dd") + "T00:00:00.000Z";
      const dayInterval = [];
      for (let i = 0; i < 4; i++) {
        dayInterval[i] = add(new Date(today), {
          days: i,
        });
      }
      this.$http
        .get("/calendar/schedule", {
          params: {
            consultationId: this.selectedOptions.type.id,
            date: today,
            daysCount: 4,
          },
        })
        .then(({ data }) => {
          this.$store.commit("SET_DAY_OPTIONS", data.body);
          this.$store.commit("SET_DAY_INTERVAL", dayInterval);
          setTimeout(() => {
            this.$store.commit("SET_LOADED_STATE", false);
          }, 300);
        });
      this.calendarInformation.options = this.selectedOptions;
      this.$store.commit("SET_SHOW_CALENDAR", true);
    },
  },
  computed: {
    ...mapGetters({
      today: "CALENDAR_DAY",
      showCalendar: "SHOW_CALENDAR",
      globalSelectedOptions: "CALENDAR_SELECT",
      reschedule: "RESCHEDULE",
      rebook: "REBOOK",
      loginUser: "CURRENT_USER_INFORMATION",
    }),
  },
  watch: {
    "selectedOptions.expert": {
      handler(value) {
        if (
          !!this.selectedOptions.language &&
          !!this.selectedOptions.services &&
          !!this.selectedOptions.type &&
          !!value &&
          !this.reschedule &&
          !this.rebook
        ) {
          setTimeout(() => {
            this.$store.commit("SET_CALENDAR_SELECT_TYPE", null);
            this.$store.commit("SET_CALENDAR_SELECT_SERVICE", null);
            this.$store.commit("SET_CALENDAR_SELECT_LANGUAGE", null);
            this.$store.commit("SET_SHOW_CALENDAR", false);
          }, 200);
        }
      },
    },
    "selectedOptions.services": {
      handler(value) {
        if (
          !!this.selectedOptions.language &&
          !!this.selectedOptions.expert &&
          !!this.selectedOptions.type &&
          !!value &&
          !this.reschedule &&
          !this.rebook
        ) {
          setTimeout(() => {
            this.$store.commit("SET_CALENDAR_SELECT_TYPE", null);
            this.$store.commit("SET_CALENDAR_SELECT_EXPERT", null);
            this.$store.commit("SET_CALENDAR_SELECT_LANGUAGE", null);
            this.$store.commit("SET_SHOW_CALENDAR", false);
          }, 200);
        }
      },
    },
    "selectedOptions.language": {
      handler(value) {
        if (
          !!this.selectedOptions.services &&
          !!this.selectedOptions.expert &&
          !!this.selectedOptions.type &&
          !!value &&
          !this.reschedule &&
          !this.rebook
        ) {
          setTimeout(() => {
            this.$store.commit("SET_CALENDAR_SELECT_TYPE", null);
            this.$store.commit("SET_CALENDAR_SELECT_EXPERT", null);
            this.$store.commit("SET_CALENDAR_SELECT_SERVICE", null);
            this.$store.commit("SET_SHOW_CALENDAR", false);
          }, 200);
        }
      },
    },
    globalSelectedOptions: {
      deep: true,
      handler(newCount) {
        this.selectedOptions = { ...newCount };
        this.selected();
      },
    },
    showCalendar: {
      deep: true,
      handler(newCount) {
        return { ...newCount };
      },
    },
    today: {
      deep: true,
      handler() {
        this.getSchedule();
      },
    },
  },
};
</script>

<style></style>
