<template>
  <div @click="viewDay(data)" class="calendarPage__dayCell">
    <div
      class="calendarPage__day"
      :class="{
        calendarPage__today: isToday,
        calendarPage__anotherMonth: isThisMonth,
      }"
    >
      <data-formatted date-format="dd" :data="data" />
    </div>
    <div v-if="!isThisMonth" class="calendarPage__daySchedule">
      <div v-for="(appointment, i) in dataValues">
        <span v-if="i < 2" class="calendarPage__dayScheduleItem">
          <data-formatted date-format="HH:mm" :data="appointment.time" />
        </span>
      </div>
      <div v-if="dataValues.length > 2" class="calendarPage__dayScheduleMore">
        {{ dataValues.length - 2 }} {{ $t("more") }}...
      </div>
    </div>
  </div>
</template>

<script>
import { isSameMonth, isToday } from "date-fns";
import DataFormatted from "@/components/components-helpers/DataFormatted";

export default {
  props: {
    data: new Date(),
    dataValues: {},
    viewMonth: Date,
  },
  name: "MonthCalendarCell",
  components: { DataFormatted },
  data() {
    return {
      appointment: [],
    };
  },
  methods: {
    viewDay(day) {
      if (this.isThisMonth) return;
      this.$store.commit("SET_CALENDAR_DAY_SELECT", day);
      this.$router.push("/schedules");
    },
  },
  computed: {
    isToday() {
      return isToday(new Date(this.data));
    },
    isThisMonth() {
      return !isSameMonth(new Date(this.viewMonth), new Date(this.data));
    },
  },
};
</script>

<style></style>
